.projects-page {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 0 92px;
}

.projects {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: auto;
    padding: 0 40px;
    padding-bottom: 50px;
}

.projects-list {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.projects-container-web {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 60%;
    color: white;
}

.projects-container-web a {
    margin-right: 10px;
}

.projects-header {
    font-size: 40px;
    display: flex;
    flex-direction: row;
    color: blueviolet;
    margin-bottom: 0.5cm;
}

.projects-name {
    display: flex;
    flex-direction: row;
    width: 350px;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
    color: black;
    margin-bottom: 10px;
}

.web-projects-name {
    display: flex;
    flex-direction: row;
    width: 450px;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
    color: #1AB3C5;
    font-size: 30px;
}

.resim {
    width: auto;
    height: 580px;
}

.web-img {
    width: auto;
    height: 350px;
}

.description {
    color: black;
    display: flex;
    width: 350px;
    text-align: center;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.projects-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0;
    flex-wrap: wrap;
}

.projects-container a {
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.mobile-projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 500px;
    color: white;
    width: 500px;
    background-color: blueviolet;
    border-radius: 20px;
    font-size: 24px;
    align-items: center;
}

.mobile-projects h3 {
    color: white;
    padding-top: 40px;
}

.web-projects h3 {
    color: white;
    padding-top: 40px;
}


.web-projects {
    display: flex;
    flex-direction: column;
    height: 500px;
    width: 500px;
    align-items: center;
    background-color: #1AB3C5;
    color: black;
    font-size: 24px;
    justify-content: center;
    border-radius: 20px;
}

.section-3 {
    margin: 80px 0;
    padding-top: 20px;
}


@media (max-width: 900px) {
    .web-img {
        width: 95%;
        height: auto;
    }

    .projects-container a {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 6 0;
    }

    .web-img-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .mobile-projects {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 400px;
        color: white;
        width: 300px;
        background-color: blueviolet;
        border-radius: 20px;
        font-size: 24px;
        align-items: center;
    }

    .web-projects {
        display: flex;
        flex-direction: column;
        height: 400px;
        width: 300px;
        align-items: center;
        background-color: #1AB3C5;
        color: white;
        font-size: 24px;
        justify-content: center;
        border-radius: 20px;
    }

    .projects-header {
        font-size: 34px;
        color: blueviolet;
    }

    .section-3 {
        margin: 10px 0;
        padding-top: 10px;
    }

    .mobile-projects h3 {
        color: white;
        padding-top: 20px;
        display: flex;
        text-align: center;
        flex-wrap: wrap;
    }

    .web-projects h3 {
        color: white;
        padding-top: 20px;
        display: flex;
        text-align: center;
        flex-wrap: wrap;
    }

    .web-projects-name {
        display: flex;
        flex-direction: row;
        width: auto;
        text-align: center;
        flex-wrap: wrap;
        justify-content: center;
    }

}